import React, { useEffect, useState } from 'react'
import { equipes, personnagesS2 } from '../Personnages'

export const JeuDeLaSemaine = ({ bg }) => {

    const [currentTime, setCurrentTime] = useState('');

    const myTeam = personnagesS2[window.localStorage.getItem('testDePerso')].team;

    useEffect(() => {
        setInterval(() => {
            setCurrentTime(formatTime(timeLeft()));
        }, 1000);

    }, [])

    //get time before sunday midnight
    const timeLeft = () => {
        const now = new Date();
        const sunday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (7 - now.getDay()) % 7);
        sunday.setHours(23, 59, 59, 999);
        return sunday - now;
    }

    //format time in days:hours:minutes:seconds
    const formatTime = (time) => {

        time = time;

        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        let hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((time % (1000 * 60)) / 1000);

        //force minutes hours and seconds to have 2 digits
        const format = (time) => {
            return time < 10 ? `0${time}` : time;
        }

        hours = format(hours);
        minutes = format(minutes);
        seconds = format(seconds);

        return `${days}:${hours}:${minutes}:${seconds}`;
    }

    return (
        <div className="jeuSemaine">
            <p className="jeuName"> 🌴 Jumping Marc </p>
            {equipes.map((equipe, index) => { 

                return <div className={index === myTeam ? "team mainTeam" : "team"} style={{ background: myTeam === index ? bg : equipe.bg }}>
                    <img src={equipe.img} alt="myTeam" />
                    <p className="nomTeam"> {equipe.nom} </p>
                    <p className="pointsTeam"> {equipe.points} </p>
                    <p className="ptsTeam"> pts </p>
                </div>

            })}
            <div className="playButton" style={{ background: bg }}>
                <p className="playText"> JOUER </p>
                <p className="time"> Temps restant {currentTime} </p>
            </div>

        </div>
    )
}
