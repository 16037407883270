import React from 'react'
import Icone from '../assets/Icone.png'
import Vague1 from '../assets/Vague1.png'
import Vague2 from '../assets/Vague2.png'
import Vague3 from '../assets/Vague3.png'

export const Accueil1 = ({ changeStep, bg }) => {
  return (
    <div style={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <img src={Icone} alt="icone" style={{ width: '80%', maxWidth: 500, marginTop: 60, zIndex: 8 }} />
      <img onClick={changeStep} src={Vague1} alt="vague" className="vague1" />
      <p onClick={changeStep} className="playButtonn" style={{ color: bg }}> REJOINDRE LES AVENTURIERS DE CHUPACABRA </p>
      <img src={Vague2} alt="vague" className="vague2" />
      <img src={Vague3} alt="vague" className="vague3" />
    </div>
  )
}
