import React, { useEffect, useState } from 'react'
import { NavBar } from './NavBar';
import Send from "../assets/Send.png"
import { Button } from './Button';
import Trash from '../assets/Trash.png';
import { personnagesS2 } from '../Personnages';


const fakeConv = [
  {
    id: 1,
    message: "Salut tout le monde !",
    time: "12:00",
  },
  {
    id: 2,
    message: "Salut ça va ?",
    time: "14:00",
  },
  {
    id: 1,
    message: "Bien et toi ? Prêt pour le jeu de la semaine ?",
    time: "15:00",
  },
  {
    id: 2,
    message: "De fouu, faut qu'on gagne !",
    time: "16:00",
  },
];


export const Chat = ({ goBack, bg }) => {

  const [message, setMessage] = useState('')
  const [changeTeam, setChangeTeam] = useState(false);
  const [conv, setConv] = useState(fakeConv);
  const [email, setEmail] = useState('');

  let tableauSameTeam = [];
  for (let i = 0; i < personnagesS2.length; i++) {
    if (personnagesS2[i].team === personnagesS2[window.localStorage.getItem('testDePerso')].team && i != window.localStorage.getItem('testDePerso')) {
      tableauSameTeam.push(personnagesS2[i]);
    }
  }

  const getCurrentDate = () => {
    const date = new Date();
    const hour = date.getHours();
    const minutes = date.getMinutes();

    //format minutes to have 2 digits
    const minutesFormated = minutes < 10 ? `0${minutes}` : minutes;
    const time = hour + ":" + minutesFormated;

    return time;
  }

  const sendText = () => {

    if (message === '') return;
    //get current time as hour


    setConv([...conv, { id: 0, message: message, time: getCurrentDate() }])
    setMessage('');
  }

  return changeTeam ?
    <>
      <NavBar arrow={() => setChangeTeam(false)} bg={bg} title={'Choisis ton équipe'} />
      <div className="filtrepage">
        <p className="checkemailp" style={{ marginBottom: 20 }}>
          Voulez vous vraiment quitter votre équipe ?
        </p>
        <Button bg={bg} action={() => setChangeTeam(false)} noarrow fontS={20} pourcent={85} contenu={"Trouver une nouvelle équipe"} />
      </div>
    </>
    :
    <div style={{ position: "fixed", top: 0, left: 0, width: '100%', height: '100vh' }}>
      <NavBar arrow={goBack} title={'Chat d\'équipe'} bg={bg} imgButton={Trash} imgButtonAction={() => setChangeTeam(true)} />
      <div className="chat">
        <div className="convplace">
          {conv.map((e, i) =>

            <div className="message" style={{ justifyContent: e.id !== 0 ? "flex-start" : "flex-end" }}>
              {e.id !== 0 && <img className="persoimg" src={tableauSameTeam[e.id - 1].img} />}
              <div style={{ maxWidth: '70%' }}>
                <p style={{ background: e.id === 0 ? 'black' : bg, color: e.id === 0 ? 'white' : 'black' }} className="messagetext">
                  {e.message}
                </p>
                <p className="messageTime" style={{ textAlign: e.id !== 0 ? 'left' : 'right' }}> {e.id === 0 ? 'Envoyé' : 'Reçu'} à {e.time} </p>
              </div>
            </div>
          )}
        </div>
        <div className="ecriture">
          <input placeholder="Votre message" type="text" value={message} onChange={(e) => setMessage(e.target.value)} />
          <img src={Send} alt="send" onClick={sendText} />
        </div>
      </div>
    </div>
}
