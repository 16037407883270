import logo from './logo.svg';
import './App.css';
import { TestDePersonnalité } from './TestDePersonnalité';
import { Button } from './components/Button';
import { Accueil1 } from './components/Accueil1';
import { useEffect, useState } from 'react';
import { NavBar } from './components/NavBar';
import ChatImg from './assets/Chat.png';
import { JeuDeLaSemaine } from './components/JeuDeLaSemaine';
import { Chat } from './components/Chat';

function App() {
  
  const [step, setStep] = useState(0);
  const [index, setNewIndex] = useState(2);

  const saison = 2;

  const changeStep = (perso) => {
  
      setStep(step + 1);

  }

  useEffect(() => {

    // let person = window.localStorage.getItem('testDePerso');
    // if(!person) return;
    // setStep(2);

  }, [])

  const backgroundcolor = saison === 1 ? '#B51636' : 'white';
  const newprops = { index, setNewIndex }
  let bg = '#FFD95C';

  return (
    <div style={{ position: 'absolute', top: 0, left: 0, overflow: 'hidden', width: '100%', minHeight: '100vh', background: backgroundcolor, zIndex: -2 }}>
      {step === 0 ? <Accueil1 changeStep={() => setStep(1)} bg={'white'} prochaineEtape={() => setStep(1)} /> : step === 1 ?
      
      <>
        <NavBar bg={bg} noarrow title={'Test de personnalité'} />
        <TestDePersonnalité bg={bg} saison={saison} goNext={changeStep} />
      </>
      
      : index === 1 ?
      <>
        <NavBar imgButton={ChatImg} title={'Jeu de la semaine'} bg={bg} imgButtonAction={() => setNewIndex(2)} />
        <JeuDeLaSemaine bg={bg} />
      </> : index === 2 ?
        <Chat goBack={() => setNewIndex(1)} bg={bg} />
      :
      <div>Erreur</div>
    } 

    </div>
  );
}

export default App;
