import React from 'react'
import Arrow from "../assets/ArrTopBlack.png"

export const NavBar = ({ arrow, title, bg, imgButton, imgButtonAction }) => {
  return (
    <div style={{ background: bg, color: 'black', boxSizing: 'border-box', padding: '0 30px', width: '100%', height: 60, display: 'flex', alignItems: 'center' }}>
        
        {arrow && <img src={Arrow} alt="arrow" onClick={arrow} />}
        <p className="titre_nav" style={{ marginLeft: arrow ? 25 : 0 }}> {title} </p>
        {imgButton && <img src={imgButton} alt="imgButton" onClick={imgButtonAction} style={{ marginLeft: 'auto' }} />}
    </div>
  )
}
