import Anne from "./assets/Anne.jpeg";
import Alexandra from "./assets/Alexandra.png";
import Marina from "./assets/Marina.png";
import Soraya from "./assets/Soraya.png";
import Marc from "./assets/Marc.png";
import Chatalere from "./assets/Chatalere.png";
import Herve from "./assets/Herve.png";
import Annick from "./assets/Annick.png";
import Ivan from "./assets/Ivan.png";
import Patrice from "./assets/Patrice.png";
import William from "./assets/William.png";
import Carole from "./assets/Carole.png";
import Marcs from './assets/Marcs.png';
import Nullos from './assets/Anne.jpeg';
import Mojitos from './assets/Mojitos.png';

const personnages = [
    { nom: 'Marc', img: Marc, desc: 'est confiant et sûr de lui. Il pense avoir la science infuse et est perduadé qu’il est source de convoitise.' },
    { nom: 'Alexandra', img: Alexandra, desc: 'est...complètement tarée. Elle rêve de devenir le seul et unique centre d’intérêt de Marc.' },
    { nom: 'Chataléré', img: Chatalere, desc: 'va droit au but. Elle est là que pour une seule chose, pécho un max de personnes.' },
    { nom: 'Soraya', img: Soraya, desc: 'est honnête et juste. C’est une femme de confiance qui reste fidèle à son coeur (de singe).' },
    { nom: 'Anne', img: Anne, desc: 'est honnête et altruiste. Grande romantique,il lui arrive de céder à la famille (euh folie).' },
    { nom: 'Marina', img: Marina, desc: 'est confiante et sportive. Elle n’assume pas sa sexualité mais est prête à y renoncer pour prouver son amour à Soraya.' },
]

const personnagesS2 = [
    { nom: 'Marc', team: 0, img: Marc, desc: `est un enfant.
    Aime : être le centre de l’attention
    N’aime pas : suivre les règles` },
    { nom: 'Alexandra', team: 0, img: Alexandra, desc: 'est une criminelle très dangereuse, sauf vis-à-vis de Marc.' },
    { nom: 'Chataléré', team: 1, img: Chatalere, desc: 'est toujours là pour pécho un max.' },
    { nom: 'Soraya', team: 1, img: Soraya, desc: ' est loyale à son côté gorille. Elle fait confiance à son instinct et est très protectrice.' },
    { nom: 'Anne', team: 1, img: Anne, desc: 'est nulle. C’est une tricheuse et une menteuse.' },
    { nom: 'Marina', team: 1, img: Marina, desc: 'peut vite se montrer violente et jalouse, malgré son amour pour le gorille.' },
    { nom: 'Annick ', team: 2, img: Annick, desc: 'n’est pas là pour l’argent. Du moins, pas au départ...' },
    { nom: 'Hervé', team: 2, img: Herve, desc: 'est un lâche et une grosse balance. Il est un peu la p*te de tout le monde.' },
    { nom: 'William', team: 0, img: William, desc: '? C’est qui William ?' },
    { nom: 'Carole', team: 2, img: Carole, desc: 'est tout le temps enHervé. Une vraie traitresse.' },
    { nom: 'Patrice', team: 2, img: Patrice, desc: 'aime le pastis parce que l’alcool, c’est cool.' },
    { nom: 'Yvan', team: 2, img: Ivan, desc: 'est complotiste. Comme par hasard...' },
]

const equipes = [
    {
        nom: 'Les Marcs',
        img: Marcs,
        points: 500,
        bg: '#AAFAC8'
    },
    {
        nom: 'Les nullos',
        img: Nullos,
        points: 300,
        bg: 'hsla(254, 75%, 71%, 1)'
    },
    {
        nom: 'Les Mojitos',
        img: Mojitos,
        points: 100,
        bg: 'hsla(197, 84%, 71%, 1)'
    }
]



export { personnages, personnagesS2, equipes };